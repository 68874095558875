<template>
  <g>
    <svg:style>
      .cls-2, .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#handle-gradient);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
                    x1="69.91"
                    :y1="doorTopHeight"
                    x2="69.91"
                    :y2="doorTopHeight + doorHeight"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" x1="69.91" y1="117.07" x2="69.91" y2="62.64" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="69.91" y1="175.69" x2="69.91" y2="121.26" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="69.91" y1="234.3" x2="69.91" y2="179.88" xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" x1="11.16" y1="148.47" x2="16.75" y2="148.47" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V17">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="`${doorLeftWidth1 + 59.44}`"
            :y="inoxY"
            width="20.93"
            :height="inoxHeight"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 117.07} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 62.64} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 62.64} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 117.07} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 117.07}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 175.69} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 121.26} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 121.26} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 175.69} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 175.69}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                data-name="glass"
                class="cls-6"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 234.31} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 179.88} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 179.88} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 234.31} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 234.31}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 21.9,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leafOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  },
}
</script>
